<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'banners' }"
          >Banner and Social Media</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="align-center d-flex justify-space-between">
        <div class="display-1 font-weight-bold my-6 text-capitalize">
          Update Banner and Social Media
        </div>
        <template v-if="!loading"> </template>
      </div>

      <v-form v-if="form" @submit.prevent="submit" ref="form">
        <v-divider class="pb-5"></v-divider>
        <div class="row">
          <div class="col-md-5">
            <label class="text-field-label">Type</label>
            <v-select
              flat
              solo
              class="mt-2"
              aspect-ratio="1"
              :items="type"
              item-text="label"
              item-value="value"
              @change="selectType"
              v-model="form.type"
              :error-messages="notes"
            ></v-select>

            <label class="text-field-label">Title</label>
            <v-text-field
              flat
              solo
              required
              counter
              class="mt-2"
              :rules="rules.title"
              maxlength="255"
              v-model="form.title"
              :error-messages="form.$getError('title')"
            ></v-text-field>

            <label class="text-field-label">Description</label>
            <v-textarea
              solo
              maxlength="255"
              name="input-7-4"
              v-model="form.description"
              placeholder="Description"
              :error-messages="form.$getError('description')"
            ></v-textarea>

            <label class="text-field-label">Url</label>
            <v-text-field
              flat
              solo
              required
              counter
              placeholder="Enter Url"
              v-model="form.url"
              :error-messages="form.$getError('url')"
            ></v-text-field>
            <div class="mt-4">
              <v-btn
                color="primary"
                class="mr-4 px-6"
                height="40px"
                width="70%"
                @click="submit"
                :loading="form.$busy"
                ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
              >
            </div>
            <div class="mt-4">
              <v-btn
                @click="showDeleteModal = true"
                height="40px"
                width="70%"
                color="error"
                ><v-icon left dark> {{ icons.remove }} </v-icon>Delete Banner
                and Social Media</v-btn
              >
            </div>
          </div>
          <div class="col" />
          <div class="col-md-6">
            <label class="text-field-label">Enable</label>
            <v-switch v-model="form.status" inset></v-switch>
            <label class="text-field-label">Photo</label>
            <br />
            <br />
            <input v-model="form.image" hidden />
            <div class="col-md-9">
              <v-btn
                @click="document.getElementById('uploader').click()"
                color="secondary"
                class="mb-2"
                :disabled="isDisabled"
              >
                {{ photoCover ? 'Change' : 'Add' }} Photo
                <v-file-input
                  hide-input
                  prepend-icon=""
                  id="uploader"
                  @change="onFileChanged"
                ></v-file-input>
              </v-btn>
            </div>
            <div class="col-md-9">
              <v-img
                size="100%"
                class="mb-4"
                v-if="photoCover"
                :src="photoCover"
              ></v-img>
              <small v-if="form.$getError('image')" class="danger">{{
                form.$getError('image')
              }}</small>
            </div>
          </div>
        </div>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Category"
      message="Are you sure you want to delete category?"
      @cancel="showDeleteModal = false"
      @confirm="removeBanner"
    ></ConfirmModal>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft, mdiTrashCan, mdiUpdate } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { each } from 'lodash'

export default {
  name: 'BannerDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      document,
      other: true,
      isDisabled: true,
      bannerType: '',
      notes: '',
      isSelecting: false,
      form: null,
      photo: [],
      photoCover: null,
      showBannerUpload: false,
      showUpload: false,
      showDeleteModal: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
      },
      rules: {
        title: [(v) => (v && v.length <= 255) || 'Max 255 characters'],
      },
      type: [
        {
          label: 'Banner',
          value: 'banner',
        },
        {
          label: 'Social Media',
          value: 'social',
        },
      ],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      bannerDetails: (state) => state.banner.bannerDetails,
    }),
  },

  methods: {
    ...mapActions({
      updateBanner: 'banner/updateBanner',
      deleteBanner: 'banner/deleteBanner',
      getBannerDetails: 'banner/getBannerDetails',
    }),

    ...mapMutations({
      clearBannerDetails: 'banner/clearBannerDetails',
    }),

    async getBanner() {
      this.loading = true
      await this.getBannerDetails(this.$route.params.id)
      this.form = new Form(this.bannerDetails)
      this.selectType(this.bannerDetails.type)
      this.form.status = this.bannerDetails.status === 'enable'
      if (this.bannerDetails.image) {
        this.photoCover = this.bannerDetails.image.url
        this.isDisabled = false
      }
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        if (['image'].includes(key)) {
          if (value && value.collection_name) {
            formData.delete(key)
          } else {
            formData.append(key, value)
          }
        } else if (['status'].includes(key)) {
          let result = ''
          if (value === true || value === 'enable') {
            result = 'enable'
          } else {
            result = 'disable'
          }
          formData.append(key, result)
        } else {
          formData.append(key, value)
        }
      })
      formData.append('_method', 'PUT')

      const data = {
        data: formData,
        id: this.$route.params.id,
      }

      this.updateBanner(data)
        .then(() => {
          this.showSnackbar(
            'Banner and Social Media successfully updated!',
            'success'
          )

          setTimeout(() => {
            this.$router.push({
              name: 'banners',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    handleFileImport() {
      this.isSelecting = true

      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      document.getElementById('uploader').click()
    },

    onFileChanged(file) {
      if (file) {
        this.show = true
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          this.photoCover = e.target.result
          let img = new Image()

          img.onload = () => {
            if (this.bannerType === 'banner') {
              if (img.width !== 828 || img.height !== 408) {
                this.showSnackbar(
                  'Your uploaded images do not meet the 828x408 pixel requirement.',
                  'error'
                )
                this.photoCover = null
                this.form.image = null
              } else {
                this.form.image = file
              }
            }
            if (this.bannerType === 'social') {
              if (img.width !== 828 || img.height !== 462) {
                this.showSnackbar(
                  'Your uploaded images do not meet the 828x462 pixel requirement.',
                  'error'
                )
                this.photoCover = null
                this.form.image = null
              } else {
                this.form.image = file
              }
            }
          }

          img.src = e.target.result
        }
      } else {
        this.photoCover = null
        this.form.image = null
      }
    },

    selectType(value) {
      this.isDisabled = false
      this.bannerType = value
      if (value === 'banner') {
        this.notes = 'Note the dimension for banner should be 828X408 pixels'
      } else {
        this.notes = 'Note the dimension for social should be 828X462 pixels'
      }
    },

    removeBanner() {
      this.loading = true

      this.deleteBanner(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'banners' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getBanner()
  },

  destroyed() {
    this.clearBannerDetails()
  },

  watch: {
    $route() {
      this.getBanner()
    },
  },
}
</script>
